<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-27 09:22:51
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-12 10:23:30
 * @Description  : 设备信息导出
-->
<template>
  <div class="container">
    <div class="back-top">
      <img src="../../assets/ic_back_white.png" alt="" srcset="" @click="back" />
      <span>{{ name }}</span>
    </div>
    <div class="id font-heavy">ID: {{ deviceId }}</div>

    <div class="title font-heavy">{{ $t("list_&_excel") }}</div>
    <div class="font-heavy">
      {{ $t("export_as_excel_or_csv") }}
    </div>
    <el-divider></el-divider>

    <div class="choose-box">
      <div class="font-heavy">{{ $t("choose_settings") }}</div>
      <el-form ref="form" :model="form">
        <el-divider></el-divider>
        <div class="date-box">
          <div class="date">
            <div class="font-heavy">{{ $t("start_date") }}</div>
            <div class="picker">
              <div class="border">
                <img src="../../assets/ic_calendar.png" alt="" />
              </div>
              <el-date-picker
                class="no-border"
                v-model="form.startDate"
                type="date"
                value-format="yyyy-MM-dd"
                :placeholder="$t('start_date')"
                @change="onChangeStart"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="date">
            <div class="font-heavy">{{ $t("end_date") }}</div>
            <div class="picker">
              <div class="border">
                <img src="../../assets/ic_calendar.png" alt="" />
              </div>
              <el-date-picker
                class="no-border"
                v-model="form.endDate"
                type="date"
                value-format="yyyy-MM-dd"
                :placeholder="$t('end_date')"
                @change="onChangeEnd"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="channel font-heavy">{{ $t("choose_channels") }}</div>
        <el-checkbox-group v-model="checkedList">
          <el-checkbox label="1">{{ $t("temperature") }}（℃）</el-checkbox>
          <el-checkbox label="2">{{ $t("humidity") }}（%）</el-checkbox>
        </el-checkbox-group>
        <div class="order font-heavy">{{ $t("order") }}</div>
        <div class="order-radio">
          <el-radio-group v-model="form.orderBy">
            <el-radio :label="1">{{ $t("newest_first") }}</el-radio>
            <el-radio :label="2">{{ $t("oldest_first") }}</el-radio>
          </el-radio-group>
        </div>
      </el-form>
      <div class="btn">
        <el-button type="primary" @click="showList">{{
          $t("show_list")
        }}</el-button>
        <!-- <el-button type="primary" @click="downloadFile('xlsx')">{{
          $t("download_excel")
        }}</el-button> -->
        <el-button type="primary" @click="exportPdf">
          {{ $t("export_pdf_report") }}
        </el-button>
        <el-button type="primary" @click="downloadFile('csv')">{{
          $t("download_csv")
        }}</el-button>
      </div>
    </div>
    <ShowListDialog ref="showListRef"></ShowListDialog>
  </div>
</template>

<script>
import { deviceShowListApi, deviceShowExcelApi, getPdfApi } from "@/api/api.js";
import ShowListDialog from "../logger/modal/ShowListDialog.vue";

export default {
  name: "",
  data() {
    return {
      deviceId: "",
      name: "",
      form: {
        orderBy: 2,
      },
      checkedList: ["1", "2"],
      timezone: 0,
    };
  },
  methods: {
    onChangeStart(val) {
      console.log("val: ", val);
    },

    onChangeEnd(val) {
      console.log("val: ", val);
    },
    back() {
      // this.goPages("/loggerDetail");
      this.$router.go(-1);
    },
    buildChannelValue() {
      console.log("this.checkedList ", this.checkedList);

      // 根据用户选择的通道构建 channel 值
      if (this.checkedList.length === 0 || this.checkedList.length === 2) {
        return 9; // 全选或未选
      } else if (this.checkedList.includes("1")) {
        return 1; // 仅选温度
      } else if (this.checkedList.includes("2")) {
        return 2; // 仅选湿度
      }
    },
    showList() {
      console.log("this.checkedList: ", this.checkedList);

      if (!this.form.startDate) {
        this.$message.error(this.$t("please_select_start_date"));

        return;
      }

      if (!this.form.endDate) {
        this.$message.error(this.$t("please_select_end_date"));

        return;
      }

      // 修正日期比较逻辑
      if (this.form.endDate <= this.form.startDate) {
        this.$message.error(this.$t("end_date_must_be_later_than_start_date"));
        return;
      }

      if (!this.form.orderBy) {
        this.$message.error(this.$t("please_select_sorting_rule"));

        return;
      }

      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
        filters: [],
        channel: this.buildChannelValue(),
        // channel: "0", //导出数据类型 0：不选择或者全选 1：温度 2：湿度
        // orderBy: "1", //排序方式 1：newest First 2:Oldest first
        ...this.form,
      };
      deviceShowListApi(params).then((r) => {
        console.log("=====", r);

        // this.$message.success(this.$t("operation_successful"));
        this.$refs.showListRef.showDialog(r.data);
        // this.dialogFormVisible = false;
        // this.$emit("refresh");
      });
    },
    downloadFile(type) {
      if (!this.form.startDate) {
        this.$message.error(this.$t("please_select_start_date"));

        return;
      }

      if (!this.form.endDate) {
        this.$message.error(this.$t("please_select_end_date"));

        return;
      }

      // 修正日期比较逻辑
      if (this.form.endDate <= this.form.startDate) {
        this.$message.error(this.$t("end_date_must_be_later_than_start_date"));

        return;
      }

      if (!this.form.orderBy) {
        this.$message.error(this.$t("please_select_sorting_rule"));
        return;
      }

      //表格导出类型，xlsx/csv/xls

      let temp = {
        filters: [],
        deviceId: this.deviceId,
        timezone: this.timezone,
        channel: this.buildChannelValue(),
        // channel: "0", //导出数据类型 0：不选择或者全选 1：温度 2：湿度
        // orderBy: "1", //排序方式 1：newest First 2:Oldest first
        ...this.form,
      };
      let params = {
        type: "DeviceDataShow",
        exportType: type,
        param: temp,
      };
      deviceShowExcelApi(params).then((response) => {
        console.log("deviceShowExcelApi---response: ", response);
        // // 如果响应类型为 blob，则直接处理文件下载
        const blob = response.data;
        console.log("Blob size:", blob.size);
        console.log("Blob type:", blob.type);
        const filename = response.headers["content-disposition"]
          ? response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0]
              .replace(/"/g, "")
          : `device_information_detail.${type === "xlsx" ? "xlsx" : "csv"}`; // 默认文件名
        console.log("type: ", type);

        this.downloadBlob(blob, decodeURI(filename));
        this.$message.success(this.$t("operation_successful"));
      });
    },
    downloadBlob(blob, filename) {
      const url = window.URL.createObjectURL(blob);
      console.log("Download URL:", url);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      console.log("Filename:", filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },

    exportPdf() {
      let params = {
        timezone: this.timezone,
        deviceId: this.deviceId,
      };
      getPdfApi(params).then((r) => {
        console.log("r: ", r);
        window.open(r.data, "_blank");
      });
    },
  },
  props: {},
  components: { ShowListDialog },
  computed: {
    selectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    selectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      // this.downloadFile();
      // this.showList();
    },
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.timezone = this.selectedTimeZone;
    let obj = JSON.parse(decodeURIComponent(this.$route.query.data));
    this.deviceId = obj.deviceId;
    this.name = obj.name;
    console.log("this.name: ", this.name);
    console.log(" this.deviceId: ", this.deviceId);
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  padding: 23px 115px 0 38px;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .back-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #1a72f4;
    border-color: #1a72f4;
    color: white;
    border-radius: 6px;
    padding: 5px 10px;
    height: 42px;
    width: 118px;
    min-width: 118px;
    img {
      width: 20px;
      margin-right: 10px;
      margin-left: 10px;
    }
    span {
      font-size: 20px;
      font-weight: 500;
      width: 100%;
    }
  }
  .id {
    margin: 20px 40px 20px 0;
  }
  .title {
    font-weight: 500;
  }
  .choose-box {
    background: white;
    border: 1px solid #dcdfe6;
    padding: 20px;
    margin-right: 388px;
    .date-box {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      .date {
        display: flex;
        flex-direction: column;
        width: 50%;
        img {
          width: 25px;
        }
      }
    }
    .channel {
      padding: 10px 0;
    }
    .order {
      padding: 10px 0;
    }
    .order-radio {
      display: flex;
      flex-direction: column;
      height: 40px;
      justify-content: space-between;
    }
    .btn {
      padding: 10px 0;
    }
  }
  /* 目标日历图标的选择器，这里的 .el-icon-date 是默认的图标类名，可能会根据Element UI的版本变化 */
  /deep/.el-date-editor .el-input__icon {
    /* 将原有的图标隐藏 */
    display: none;
  }

  /* 添加自定义的图标 */
  /deep/.el-date-editor .el-input__suffix {
    /* 清除默认的图标空间 */
    padding-right: 0;
  }

  /deep/.el-date-editor .el-input__suffix:before {
    // content: url('../../assets/ic_calendar.png'); /* 替换为你的自定义图标路径 */
    // position: absolute;
    // right: 10px; /* 根据需要调整位置 */
    // top: 50%;
    // transform: translateY(-50%);
  }
  .no-border /deep/.el-input__inner {
    border: none;
    box-shadow: none;
  }
  .no-border {
    border: 1px solid #dcdfe6;
    height: 40px;
    width: 85%;
    margin: 10px 0;
  }

  .picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    .border {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #dcdfe6;
      border-right: 0px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>
